<script setup lang="ts">
import CornerLogo from '@/components/CornerLogo.vue';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import RouterLink from '@/components/RouterLink.vue';
import Button from '@/components/ui/button/Button.vue';
import { ArrowRightIcon } from '@radix-icons/vue';
import { useSeoMeta } from '@unhead/vue';
import { useEventListener } from '@vueuse/core';
import { Head } from 'vike-vue/Head';
import { ref } from 'vue';
import logo from '@/assets/parsec-logo-color-symbol.png';
// import { useRouter } from 'vue-router';

const PARALLAX_STRENGTH = 20;

const y = ref(0);

function onScroll() {
  y.value = window.scrollY * (PARALLAX_STRENGTH / 100);
}

useEventListener('scroll', onScroll);

useSeoMeta({
  title: 'Parsec Skateboards',
});

// const router = useRouter();

// onMounted(() => {
//   router.push('/products/aero-pro');
// });
</script>

<template>
  <Head>
    <title>Parsec Skateboards</title>
    <meta name="description" content="Welcome to the Lightweight Revolution." />

    <meta name="og:image" :content="logo" />
    <meta name="og:image:width" content="256" />
    <meta name="og:image:height" content="256" />
  </Head>

  <main>
    <Header />

    <section class="parallax hero" :style="{ '--y': `${y}px` }">
      <CornerLogo class="hidden md:block" />

      <div class="hero-content">
        <div class="md:hidden"></div>

        <div
          class="text-center mix-blend-difference text-white text-[4rem] sm:text-[5rem] md:text-[6rem] lg:text-[7.5rem] leading-32 font-bold"
        >
          <h1>Portable.</h1>
          <h1>Personal.</h1>
          <h1>Premium.</h1>
        </div>

        <Button
          variant="accent"
          size="lg"
          class="h-14 flex items-center bg-accent hover:bg-accent-hover text-white rounded-2xl"
        >
          <RouterLink to="/products/aero-pro">
            <span class="text-lg font-semibold">Join the Lightweight Revolution</span>
          </RouterLink>
          <ArrowRightIcon class="size-6" />
        </Button>
      </div>
    </section>

    <Footer />
  </main>
</template>

<style>
.hero {
  @apply relative bg-primary rounded-lg p-4 col-span-12 h-[calc(100vh-6.75rem)] md:h-[calc(100vh-3rem)] min-h-[800px];

  > .hero-content {
    @apply w-full h-full flex flex-col items-center justify-between py-24 md:justify-center md:-mt-24 pointer-events-none;

    > * {
      pointer-events: auto;
    }
  }
}

.parallax {
  @apply bg-cover md:bg-contain;

  background-image: url('@/assets/img/hero.webp');

  @media screen and (max-width: 768px) {
    background-image: url('@/assets/img/hero-mobile.webp');
  }

  @media screen and (min-width: 2160px) {
    background-image: url('@/assets/img/hero.png');
  }

  background-position-x: center;
  background-position-y: calc(50% + var(--y));
  background-repeat: no-repeat;
}
</style>
